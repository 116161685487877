.app {
  background-color: #F9FBFD;
  display         : flex;
  justify-content : center;
}

.content {
  max-width: 1080px;
}

.title {
  font-family: "BrownRegular", "Helvetica", sans-serif;
  max-width  : 356px;
  font-size  : 40px;
  color      : #323232;
  margin     : 0;
}

.subtitle {
  font-family: "BrownRegular", "Helvetica", sans-serif;
  color      : #AEBAC2;
  max-width  : 356px;
  font-size  : 20px;
  margin     : 2vw 0;
}

header {
  display        : flex;
  justify-content: center;
  align-items    : center;
}

.logo {
  width       : 300px;
  margin-right: 96px;
}

.header_left {
  display       : flex;
  flex-direction: column;
  margin        : 96px;
}

.buttons {
  width  : 100%;
  display: flex;
}

.button {
  color           : #335DEA;
  background-color: #EAEFFD;
  font-family     : "BrownRegular", "Helvetica", sans-serif;
  font-size       : calc(0.35vw + 10px);
  padding         : 0.75em;
  cursor          : pointer;
  width           : fit-content;
  height          : fit-content;
  border-radius   : 0.5em;
  text-decoration : none;
  margin-right    : calc(0.7vw + 20px);
}

.button:hover {
  background-color: #d5dffc;
}

.cards {
  display       : flex;
  flex-direction: column;
  align-items   : center;
  padding-bottom: 100px;
}

.card {
  background-color: white;
  box-shadow      : 0px 0px 16px rgba(0, 0, 0, 0.02);
  text-decoration : none;
  color           : #323232;
  width           : 770px;
  border-radius   : calc(0.5vw + 2px);
  height          : 256px;
  margin          : calc(1vw + 4px);
  display         : flex;
  justify-content : space-between;
}

.card_text {
  margin        : calc(1vw + 16px);
  display       : flex;
  flex-direction: column;
  font-family   : "BrownRegular", "Helvetica", sans-serif;
}

.card_img {
  border-radius: calc(0.5vw + 2px);
  height       : 100%;
}

h2 {
  font-size: 26px;
}

.right {
  text-align: right;
}

.card_p {
  font-size: 16px;
  width    : 130%;
  margin   : 10px 0;
}

.card_p.right {
  margin-left: -30%;
}

.mask1 {
  clip-path: circle(150% at 150% 35%);
}

.mask2 {
  clip-path: circle(150% at -50% 37%);
}

.mask3 {
  clip-path: circle(150% at 150% 65%);
}

@media (max-width: 900px) {

  .title {
    font-size: 36px;
  }

  .subtitle {
    font-size: 16px;
    margin   : 24px 0;
  }


  .logo {
    margin: auto 32px;
  }

  header {
    flex-direction: column;
    height        : fit-content;
  }

  .header_left {
    margin   : auto;
    max-width: 80vw;
  }

  .buttons {
    width : auto;
    margin: auto;
  }

  .button {
    font-size: calc(16px);
  }

  .card {
    width : 100%;
    margin: 0px;
  }

  .card_img {
    width: 55%;
  }
}


.ownership {
  text-align: center;
  font-family: "BrownRegular", "Helvetica", sans-serif;
  color: #AEBAC2;
  padding: 20px 0;
  background-color: #F1F3F5;
  border-top: 1px solid #E0E0E0;
  width: 100%;
}